import { appRoles } from './utils/appRoles.js';

const newClientsRoute = {
  path: 'new-client',
  name: 'NewClient',

  component: () => import('@/views/Modals/NewClient.vue'),
  children: [
    {
      path: 'employer-info',
      name: 'NewClientEmployerInfo',
      meta: {
        title: 'Employer information',
      },
    },
    {
      path: 'contact-details',
      name: 'NewClientContactDetails',
      meta: {
        title: 'Contact details',
      },
    },
    {
      path: 'additional-details',
      name: 'NewClientAdditionalDetails',
      meta: {
        title: 'Additional details',
      },
    },
  ],
};
const clientEventsRoute = {
  path: 'events/:eventType/:projectId',
  name: 'ClientEvents',
  component: () => import('@/views/Modals/ClientEvents.vue'),
  meta: {
    rolesRequired: [appRoles.AP, appRoles.PM],
  },
  redirect: { name: 'ClientEventsSelectCoverages' },
  props: true,
  children: [
    {
      path: 'select-coverages',
      name: 'ClientEventsSelectCoverages',
      props: true,
      component: () => import('@/components/ClientEvents/SelectCoverages/index.vue'),
      meta: {
        title: 'Select coverages',
      },
    },
    {
      path: 'marketing-details',
      name: 'ClientEventsMarketingDetails',
      props: true,
      component: () => import('@/components/ClientEvents/MarketingDetails/index.vue'),
      meta: {
        title: 'Marketing details',
      },
    },
    {
      path: 'renewal-details',
      name: 'ClientEventsRenewalDetails',
      props: true,
      component: () => import('@/components/ClientEvents/RenewalDetails/index.vue'),
      meta: {
        title: 'Renewal details',
      },
    },
    {
      path: 'coverage-details/:productTypeId',
      name: 'ClientEventsCoverageDetails',
      component: () => import('@/components/ClientEvents/CoverageDetails/index.vue'),
      meta: {
        title: 'Coverage details',
      },
    },
    {
      path: 'new-coverage-details/:productTypeId',
      name: 'ClientEventsNewCoverageDetails',
      props: true,
      component: () => import('@/components/ClientEvents/NewCoverageDetails/index.vue'),
      meta: {
        title: 'New coverage details',
      },
    },
    {
      path: 'plan-alternative-details',
      name: 'ClientEventsPlanAlternativeDetails',
      props: true,
      component: () => import('@/components/ClientEvents/Alternatives/PlanAlternativeDetails.vue'),
      meta: {
        title: 'Plan alternative details',
      },
    },
    {
      path: 'plan-alternative-forms/:productTypeId',
      name: 'ClientEventsSelfServicePlanAlternatives',
      props: true,
      component: () => import('@/components/ClientEvents/PlanAlternativeDetails/index.vue'),
      meta: {
        title: 'Plan alternative details',
      },
    },
    {
      path: 'review-coverages/:projectProductId',
      name: 'ClientEventsReviewCoverages',
      props: true,
      component: () => import('@/components/ClientEvents/ReviewCoverages/index.vue'), // placeholder
      meta: {
        title: 'Review coverages',
      },
    },
    {
      path: 'collect-files',
      name: 'ClientEventsSources',
      props: true,
      component: () => import('@/components/ClientEvents/Sources.vue'),
      meta: {
        title: 'Collect files',
      },
    },
    {
      path: 'renewal-contacts',
      name: 'ClientEventsRenewalContacts',
      props: true,
      component: () => import('@/components/ClientEvents/Contacts.vue'),
      meta: {
        title: 'Renewal contacts',
      },
    },
    {
      path: 'select-carriers',
      name: 'ClientEventsSelectCarriers',
      component: () => import('@/components/ClientEvents/Carriers.vue'),
      meta: {
        title: 'Select carriers',
      },
      props: true,
    },
    {
      path: 'carrier-contacts',
      name: 'ClientEventsSelectContacts',
      component: () => import('@/components/ClientEvents/Contacts.vue'),
      meta: {
        title: 'Carrier contacts',
      },
      props: true,
    },
    {
      path: 'review-and-finish',
      name: 'ClientEventsFinish',
      props: true,
      component: () => import('@/components/ClientEvents/Finish/index.vue'),
      meta: {
        title: 'Review and finish',
      },
    },
  ],
};
/**
 * Clone a route (including any children) and prefix all the routes with a string,
 * so that we can embed an identical set of routes on our routes file without having
 * to duplicate the entire block.
 *
 * For example, in the broker site, the ClientEvents are used as-is for the client events routes under ClientOverview,
 * and for ClientHistory, it and all it’s children are cloned and prefixed with "ClientHistory".
 *
 * @param {string} prefix
 * @param {object} route
 * @param {Array} route.children
 * @param {object} route.component
 * @param {string} route.path
 * @param {object} route.props
 * @param {string} route.name
 * @param {object} route.meta
 * @returns {object}
 */
// eslint-disable-next-line default-param-last
const prefixRoute = (prefix = '', {
  children = [],
  component,
  path,
  props,
  name,
  meta,
}) => ({
  children: children.map((child) => prefixRoute(prefix, child)),
  component,
  path,
  props,
  name: prefix + name,
  meta: {
    ...meta,
    prefix,
  },
});

export const routes = [
  {
    path: '/sign-in',
    alias: '/login',
    name: 'SignInPage',
    component: () => import('@/views/Auth/SignIn.vue'),
    meta: {
      noAuthRequired: true,
      redirectIfAuthenticated: true,
      title: 'Sign in',
    },
  },
  {
    path: '/sso',
    name: 'SsoSignin',
    component: () => import('@/views/Auth/SsoSignin.vue'),
    meta: {
      noAuthRequired: true,
      redirectIfAuthenticated: true,
      title: 'Authenticating with SSO',
    },
  },
  {
    path: '/register/:token',
    name: 'Register',
    component: () => import('@/views/RegisterWithInvite.vue'),
    props: true,
    meta: {
      noAuthRequired: true,
      redirectIfAuthenticated: true,
      title: 'Register',
    },
  },
  {
    name: 'Support',
    path: '/support',
    meta: {
      title: 'Support',
    },
    /**
     * Opens support page on separate tab/window.
     */
    beforeEnter() {
      window.open(
        'https://support.threeflow.com/en',
        '_blank',
      );
    },
  },
  {
    path: '/terms',
    name: 'TermsOfUse',
    meta: {
      noAuthRequired: true,
      title: 'Terms of use',
    },
    component: () => import('@/views/Auth/TermsOfUse.vue'),
  },
  {
    path: '/am-best-disclosure',
    name: 'AmBestDisclosure',
    meta: {
      title: 'AM Best disclosure',
    },
    component: () => import('@/views/AmBestDisclosure.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/Auth/ForgotPassword.vue'),
    meta: {
      noAuthRequired: true,
      redirectIfAuthenticated: true,
      title: 'Forgot password',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Dashboards/index.vue'),
    children: [
      {
        component: () => import('@/views/Dashboards/Insights.vue'),
        name: 'Insights',
        path: 'insights',
        meta: {
          title: 'Dashboard',
          rolesRequired: [appRoles.IS],
        },
      },
      {
        path: 'my-clients',
        name: 'MyClients',
        component: () => import('@/views/Dashboards/MyClients.vue'),
        meta: {
          title: 'My clients',
          rolesRequired: [appRoles.CM, appRoles.AP, appRoles.PM],
        },
        children: [
          prefixRoute('MyClients', newClientsRoute),
        ],
      },
      {
        path: 'all-clients',
        name: 'AllClients',
        component: () => import('@/views/Dashboards/AllClients.vue'),
        meta: {
          title: 'All clients',
          rolesRequired: [appRoles.CM, appRoles.AP, appRoles.PM],
        },
        children: [
          prefixRoute('AllClients', newClientsRoute),
        ],
      },
      {
        path: '/all-current-coverages',
        name: 'AllCurrentCoverages',
        component: () => import('@/views/Dashboards/AllCurrentCoverages.vue'),
        meta: {
          title: 'Current coverages',
          rolesRequired: [appRoles.CM, appRoles.AP, appRoles.PM],
        },
      },
    ],
  },
  {
    path: '/client/:clientId',
    name: 'ClientRecord',
    redirect: { name: 'ClientOverview' },
    component: () => import('@/views/ClientRecord/index.vue'),
    meta: {
      rolesRequired: [appRoles.AP, appRoles.PM, appRoles.CM],
    },
    props: (route) => ({
      ...route.params,
    }),
    children: [
      {
        path: 'client-overview',
        alias: 'client-overview/edit-client', // alias the edit modal
        name: 'ClientOverview',
        component: () => import('@/views/ClientRecord/ClientOverview.vue'),
        props: true,
        meta: {
          title: 'Client overview',
        },
        children: [
          clientEventsRoute,
          {
            path: 'local-renewal-schedules/:productTypeId',
            name: 'LocalRenewalSchedulePanel',
            props: true,
            component: () => import('@/views/Panels/LocalRenewalSchedule.vue'),
          },
          {
            path: 'new-current-coverage/',
            name: 'NewCurrentCoverage',
            component: () => import('@/views/NewCurrentCoverage.vue'),
            redirect: { name: 'NewCurrentCoverageAddCoverage' },
            props: true,
            children: [
              {
                path: 'add-lines-of-coverage',
                name: 'NewCurrentCoverageAddCoverage',
                component: () => import('@/components/NewCurrentCoverage/AddLinesOfCoverage.vue'),
                meta: {
                  title: 'Add current coverage',
                },
                props: true,
              },
              {
                path: 'lines-of-coverage/:productTypeId',
                name: 'NewLinesOfCoverage',
                component: () => import('@/components/NewCurrentCoverage/LinesOfCoverage.vue'),
                meta: {
                  title: 'Lines of coverage',
                },
                props: true,
              },
              {
                path: 'service-contacts/:carrierId',
                name: 'NewCurrentCoverageServiceContacts',
                component: () => import('@/components/NewCurrentCoverage/ServiceContacts.vue'),
                meta: {
                  title: 'Service contacts',
                },
                props: true,
              },
              {
                path: 'sources/:fileCategoryType',
                name: 'NewCurrentCoverageSources',
                component: () => import('@/components/NewCurrentCoverage/Sources.vue'),
                meta: {
                  title: 'Files',
                },
                props: true,
              },
            ],
          },
          {
            path: 'new-medical-coverage/',
            name: 'NewMedicalCoverage',
            component: () => import('@/views/NewMedicalCoverage.vue'),
            redirect: { name: 'NewMedicalCoverageAddPlans' },
            props: true,
            /**
             * Because our validations require a user to visit the page we want to make sure that a user starts
             * at step one in the event that they reload the page for some reason.
             *
             * @param {object} to
             * @param {object} from
             * @param {Function} next
             */
            beforeEnter: (to, from, next) => {
              if (!from.name && to.name !== 'NewMedicalCoverageAddPlans') {
                next({
                  name: 'NewMedicalCoverageAddPlans',
                  params: to.params,
                });
              }
              next();
            },
            children: [
              {
                path: 'add-medical-plans',
                name: 'NewMedicalCoverageAddPlans',
                component: () => import('@/components/NewMedicalCoverage/AddPlans/Index.vue'),
                meta: {
                  title: 'Add medical plans',
                },
                props: true,
              },
              {
                path: 'add-medical-plan-details',
                name: 'NewMedicalCoverageAddPlanDetails',
                component: () => import('@/components/NewMedicalCoverage/AddPlanDetails/Index.vue'),
                meta: {
                  title: 'Add medical plan details',
                },
                props: true,
              },
              {
                path: 'medical-plan/:planId',
                name: 'NewMedicalCoveragePlan',
                component: () => import('@/components/NewMedicalCoverage/AddPlanDetails/Index.vue'),
                meta: {
                  title: 'Add medical plan',
                },
                props: true,
              },
              {
                path: 'add-medical-files',
                name: 'NewMedicalCoverageAddFiles',
                component: () => import('@/components/NewMedicalCoverage/AddFiles/Index.vue'),
                meta: {
                  title: 'Add medical files',
                },
                props: true,
              },
            ],
          },
          {
            path: 'add-files/:documentId/:categoryType',
            name: 'AddClientRecordFiles',
            component: () => import('@/views/Modals/ClientRecordSources.vue'),
            meta: {
              title: 'Add current coverage files',
            },
            props: true,
          },
          {
            path: 'add-census-files',
            name: 'AddCensusSources',
            component: () => import('@/views/Modals/ClientRecordCensusSources.vue'),
            meta: {
              title: 'Add census files',
            },
            props: true,
          },
          {
            path: 'add-broker-team-members',
            name: 'AddBrokerTeamContact',
            component: () => import('@/views/Modals/AddBrokerTeamContact.vue'),
            meta: {
              title: 'Add broker team members',
            },
            props: true,
          },
        ],
      },
      {
        path: 'coverage',
        name: 'Coverage',
        redirect: 'coverage/current',
        component: () => import('@/views/ClientRecord/Coverage/index.vue'),
        meta: {
          title: 'Coverage',
        },
        props: true,
        children: [
          {
            path: ':coverageType/:productTypeId?',
            alias: ':coverageType/:productTypeId?/edit-client', // alias the edit modal
            name: 'CoverageType',
            component: () => import('@/views/ClientRecord/Coverage/CoverageType.vue'),
            meta: {
              title: 'Coverage',
            },
            props: true,
            children: [
              {
                path: 'add-files/:documentId/:categoryType',
                name: 'AddCoverageSources',
                component: () => import('@/views/Modals/CoverageSources.vue'),
                meta: {
                  title: 'Add coverage files',
                },
                props: true,
              },
              {
                path: 'add-contacts/:carrierId',
                name: 'AddServiceContact',
                component: () => import('@/views/Modals/AddServiceContact.vue'),
                meta: {
                  title: 'Add service contact to current coverage',
                },
                props: true,
              },
              {
                path: 'edit-current-coverage/:carrierId',
                name: 'EditCurrentCoverage',
                component: () => import('@/views/Modals/EditCurrentCoverage.vue'),
                meta: {
                  title: 'Edit current coverage',
                },
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'medical-coverage',
        name: 'MedicalCoverage',
        redirect: 'medical-coverage/current',
        component: () => import('@/views/ClientRecord/MedicalCoverage/Index.vue'),
        meta: {
          title: 'Medical Coverage',
        },
        props: true,
        children: [
          {
            path: 'coverages/:coverageTypeId/plan/:planId?',
            name: 'MedicalCoveragePlan',
            component: () => import('@/views/ClientRecord/MedicalCoverage/PlanDetails.vue'),
            meta: {
              title: 'Medical Coverage',
            },
            props: true,
            children: [
              {
                path: 'add-files/:documentId/:categoryType',
                name: 'AddMedicalCoverageSources',
                component: () => import('@/views/Modals/CoverageSources.vue'),
                meta: {
                  title: 'Add medical coverage files',
                },
                props: true,
              },
              {
                path: 'add-contacts/:carrierId',
                name: 'AddMedicalServiceContact',
                component: () => import('@/views/Modals/AddServiceContact.vue'),
                meta: {
                  title: 'Add service contact to current medical coverage',
                },
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'client-history/',
        alias: 'client-history/edit-client', // alias the edit modal
        name: 'ClientHistory',
        component: () => import('@/views/ClientRecord/History/index.vue'),
        props: true,
        meta: {
          title: 'Client history',
        },
        children: [
          {
            path: 'coverage',
            name: 'CoverageHistory',
            component: () => import('@/views/ClientRecord/History/Coverage.vue'),
            meta: {
              title: 'Coverage History',
            },
            props: true,
          },
          {
            path: 'marketing',
            name: 'MarketingHistory',
            component: () => import('@/views/ClientRecord/History/Marketing.vue'),
            meta: {
              title: 'Marketing History',
            },
            props: true,
          },
        ],
      },
      {
        path: 'documents/',
        name: 'Documents',
        component: () => import('@/views/ClientRecord/Documents.vue'),
        props: true,
        meta: {
          title: 'Client Documents',
        },
      },
    ],
  },
  {
    path: '/manage-renewal-schedules',
    name: 'RenewalSchedulesManagement',
    component: () => import('@/views/RenewalScheduler/index.vue'),
    meta: {
      title: 'Manage renewal schedules',
    },
    props: true,
    children: [
      {
        path: ':productTypeId/:carrierId?',
        name: 'RenewalScheduleContacts',
        component: () => import('@/views/Modals/RenewalScheduleContacts.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/manage-users',
    name: 'BrokerManagement',
    component: () => import('@/views/BrokerManagement/index.vue'),
    meta: {
      title: 'Manage users',
      rolesRequired: [appRoles.BM],
    },
    redirect: { name: 'BrokerUsers' },
    props: true,
    children: [
      {
        path: 'users/:userId?',
        name: 'BrokerUsers',
        props: true,
        component: () => import('@/views/BrokerManagement/BrokerUsers.vue'),
        children: [
          {
            path: 'deactivate-broker-user',
            name: 'DeactivateBrokerUser',
            props: true,
            component: () => import('@/views/DeactivateBrokerUser.vue'),
            meta: {
              title: 'Deactivate broker user',
            },
          },
          {
            path: 'replace-broker-user',
            name: 'ReplaceBrokerUser',
            props: true,
            component: () => import('@/views/ReplaceBrokerUser.vue'),
            meta: {
              title: 'Replace broker user',
            },
          },
        ],
      },
      {
        path: 'invites',
        name: 'BrokerInvites',
        component: () => import('@/views/BrokerManagement/BrokerInvites.vue'),
        props: true,
      },
    ],
  },
  // This is to redirect to the product analysis view when hitting `/projects/123`
  {
    path: '/projects/:projectId/',
    redirect: '/projects/:projectId/product/analysis',
  },
  {
    path: '/projects/:projectId/:view/:tableViewId?',
    name: 'ProjectOverview',
    component: () => import('@/views/Project.vue'),
    meta: {
      title: 'Quote view',
      rolesRequired: [appRoles.AP, appRoles.PM, appRoles.CM],
    },
    props: true,
    children: [
      // pages
      {
        path: 'rfp',
        name: 'RfpDeliveryLanding',
        component: () => import('@/views/RfpDelivery/Landing.vue'),
        children: [
          {
            path: 'document-manager',
            name: 'RfpDocumentManager',
            meta: {
              title: 'Document Manager',
            },
            component: () => import('@/views/RfpDelivery/RfpDocumentManager.vue'),
          },
          {
            path: 'invite-carriers',
            name: 'RfpDeliveryInviteCarriers',
            meta: {
              title: 'Add Carriers',
            },
            component: () => import('@/views/RfpDelivery/InviteCarriers.vue'),
          },
          {
            path: 'add-carrier-users',
            name: 'InviteCarrierUsers',
            meta: {
              title: 'Add Recipients',
            },
            component: () => import('@/views/RfpDelivery/InviteCarrierUsers.vue'),
          },
          {
            path: 'due-date',
            name: 'RfpDueDate',
            meta: {
              title: 'Select RFP Due Date',
            },
            component: () => import('@/views/RfpDelivery/RfpDueDate.vue'),
          },
          {
            path: 'overview',
            name: 'RfpOverview',
            meta: {
              title: 'RFP Overview',
            },
            component: () => import('@/views/RfpDelivery/RfpOverview.vue'),
          },
          {
            path: 'manage-invites',
            name: 'ManageInvitesHome',
            meta: {
              title: 'Manage Invites',
            },
            component: () => import('@/views/RfpDelivery/ManageInvitesHome.vue'),
          },
          {
            path: 'update-rfp',
            name: 'UpdateRfpDetailsHome',
            meta: {
              title: 'RFP Editor',
            },
            component: () => import('@/views/RfpDelivery/UpdateRfpDetailsHome.vue'),
          },
        ],
      },
      // panels
      {
        path: 'comments',
        name: 'CommentsPanel',
        meta: {
          title: 'Comments',
        },
        component: () => import('@/views/Panels/Comments.vue'),
      },
      {
        name: 'AddProductAlternativePanel',
        path: 'add-product-alternative',
        component: () => import('@/views/InFlightAlternatives/index.vue'),
        meta: {
          title: 'Add Product Alternative',
          rolesRequired: [appRoles.AP, appRoles.PM],
        },
        props: true,
        children: [
          {
            name: 'SelectProductAlternatives',
            path: 'select-alternatives',
            component: () => import('@/views/InFlightAlternatives/SelectPlanAlternatives.vue'),
            meta: {
              title: 'Select Product Alternative',
            },
          },
          {
            name: 'PlanAlternativeForm',
            path: 'plan-alternative/:inforceProductId',
            component: () => import('@/views/InFlightAlternatives/PlanAlternativeForm.vue'),
            meta: {
              title: 'Add Product Alternative',
            },
            props: true,
          },
          {
            name: 'FinalizeInFlightAlternatives',
            path: 'review-alternatives',
            component: () => import('@/views/InFlightAlternatives/InFlightReview.vue'),
            meta: {
              title: 'Review Product Alternatives',
            },
          },
        ],
      },
      {
        path: 'volume-entry/:volumeEntryId',
        name: 'VolumeEntryPanel',
        meta: {
          title: 'Volume Entry',
        },
        props: true,
        component: () => import('@/views/VolumeEntry.vue'),
      },
      {
        path: 'quote-edits',
        name: 'QuoteEditsPanel',
        meta: {
          title: 'Quote edits',
        },
        component: () => import('@/views/Panels/QuoteEdits.vue'),
      },
      {
        path: 'admin-fees/:adminFeeId?',
        name: 'AdminFeesPanel',
        meta: {
          title: 'Admin Fees',
        },
        props: true,
        component: () => import('@/components/Financials/StopLossProducts/AdminFeesEntry.vue'),
      },
      {
        path: 'firm-quote/:selectedCarrierId?',
        name: 'FirmQuotePanel',
        meta: {
          title: 'Request firm quote',
        },
        props: true,
        component: () => import('@/views/Panels/FirmQuote.vue'),
      },
      {
        path: 'select-finalist/:productTypeId',
        name: 'SelectFinalists',
        meta: {
          title: 'Select finalists',
        },
        props: true,
        component: () => import('@/views/SelectFinalists.vue'),
      },
    ],
  },
  {
    path: '/examples/',
    name: 'Examples',
    redirect: '/examples/AppModalNotify',
    component: () => import('@/views/Examples/index.vue'),
    children: [
      {
        component: () => import('@/views/Examples/ProjectFileManager.vue'),
        path: 'project-file-manager',
        name: 'ProjectFileManager',
        meta: {
          title: 'ProjectFileManager',
          description: '<ProjectFileManagerCategory /> used throughout the broker application.',
        },
      },
      {
        path: 'AppModalNotify',
        name: 'ModalNotifyExample',
        component: () => import('@/views/Examples/ModalNotify.vue'),
        meta: {
          title: 'AppModalNotify',
          description: '<AppModalNotify /> used throughout the broker application.',
        },
      },
      {
        path: 'panel',
        name: 'AppPanelExample',
        component: () => import('@/views/Examples/Panel.vue'),
        meta: {
          title: 'AppPanel',
          description: '<AppPanel /> used throughout the broker application.',
        },
      },
    ],
  },
  {
    path: '/medical/projects/:projectId/',
    name: 'Medical',
    component: () => import('@/views/MultiCarrier/ProjectDashboard.vue'),
    meta: {
      title: 'Project Overview',
    },
    props: true,
    children: [],
  },
  {
    path: '*',
    component: () => import('@/views/NotFound.vue'),
  },
];

export default routes;
